.pin-input {
  width: 75px;
  height: 75px;
  font-size: 40px;
  text-align: center;
  outline: none;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  transition-property: color,border,box-shadow,transform;
  transition-duration: .25s;
}

.pin-input:focus {
  outline: none;
  box-shadow: 0 0 7px rgb(104 109 224 / 50%);
  border: 1px solid #686de0;
  transform: scale(1.05);
}

.pin-input.correct {
  border: 1px solid #6ab04c;
  color: #6ab04c;
}

.pin-input.incorrect,
form.incorrect input {
  border: 1px solid #c73939;
  color: #c73939;
}

.pin-input[disabled] {
  background: rgba(106,176,76,.1);
  opacity: .5;
  cursor: not-allowed;
}

footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 45px;
  width: 100%;
  background-color: #01579b;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FEFEFE;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

a {
  color: #01579b;
}

a:hover,
a:focus {
  text-decoration: underline;
}

.canvasContainer > canvas {
  margin-left:auto;
  margin-right:auto;
}
